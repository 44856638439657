import { Box, useColorModeValue } from "@chakra-ui/react";

export function Paper({ useFlex = false, ...props }) {
  return (
    <Box
      bg="bg-surface"
      shadow="md"
      borderWidth="1px"
      borderColor="bg-subtle"
      borderRadius={10}
      p={{ base: 4, md: 8 }}
      {...props}
    />
  );
}
